<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 bg-reset-password">

      <b-row class="navbar-auth-custom px-0 mx-0 col-sm-12">
        <b-col
          cols="7"
        >
          <!-- Brand logo-->
          <brand-logo />
          <!-- /Brand logo-->
        </b-col>
      </b-row>
      <!-- Reset password-->
      <b-col
        :lg="isEmediaPlatform ? 12 : 7"
        class="d-flex align-items-center auth-bg py-lg-3 px-xl-0 pt-1 form-resetPassword px-2"
      >
        <b-col
          :sm="isEmediaPlatform ? 12 : 8"
          md="8"
          :lg="isEmediaPlatform ? 8 : 9"
          :xl="isEmediaPlatform ? 4 : 7"
          class="form-reset-password mx-auto max-width mt-lg-3 mt-xl-1"
        >
          <b-card-title
            title-tag="h1"
            class="font-medium mb-1 text-center"
          >
            {{ $t('resetPW.textWelcome') }}
          </b-card-title>
          <b-card-text class="mb-4 text-center theme-text-secondary">
            {{ $t('resetPW.textGuide') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="simpleRules"
            v-slot="{ invalid }"
          >
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="validationForm"
            >

              <!-- password -->
              <b-form-group
                label-for="reset-password-new"
              >
                <div class="d-flex justify-content-between">
                  <label class="form-label">
                    {{ $t('resetPW.labelNewPassword') }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  vid="Password"
                  rules="required|password"
                  :name="$t('resetPW.labelNewPassword')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model.trim="password"
                      id="reset-password-new"
                      class="form-control-merge input-height"
                      name="reset-password-new"
                      :placeholder="$t('resetPW.placeholderNewPW')"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                class="my-2"
                label-for="reset-password-confirm"
              >
                <label class="form-label">
                  {{ $t('resetPW.labelConfirmPassword') }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|confirmed:Password"
                  :name="$t('resetPW.labelConfirmPassword')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model.trim="cPassword"
                      id="reset-password-confirm"
                      class="form-control-merge input-height"
                      name="reset-password-confirm"
                      :placeholder="$t('resetPW.placeholderConfirmPW')"
                      :type="password2FieldType"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-card-text class="d-flex text-left mt-3">
                <b-input-group-append
                  is-text
                  class="mr-1 input-group-append-validate"
                >
                  <feather-icon
                    :icon="password1check"
                  />
                </b-input-group-append>
                <span>{{ $t('resetPW.textLengthPassword') }}</span>
              </b-card-text>
              <b-card-text class="d-flex text-left mt-1">
                <b-input-group-append
                  is-text
                  class="mr-1"
                >
                  <feather-icon
                    :icon="password2check"
                    variant="success"
                  />
                </b-input-group-append>
                <span>{{ $t('resetPW.textMatchPassword') }}</span>
              </b-card-text>

              <!-- submit button -->
              <btn-loading
                block
                type="submit"
                variant-convert="btn-submit"
                class="btn-confirm-password my-3 input-height d-flex justify-content-center align-items-center txt-color"
                pill
                :loading="loading"
                :disabled="invalid"
              >
                Reset Password
              </btn-loading>
            </b-form>
          </validation-observer>

          <!-- <div class="mt-2 mb-2 text-center">
            <p class="text-or">
              OR
            </p>
          </div> -->
          <b-card-text class="text-center mt-2">
            <span>{{ $t('resetPW.textHaveAccount') }}</span>
            <a
              href="/login"
              @click="logout"
            >
              <span>&nbsp;
                {{ $t('resetPW.btnLogin') }}
              </span>
            </a>
          </b-card-text>

        </b-col>
      </b-col>
      <!-- /Reset password-->

      <!-- Left Text-->
      <left-image v-if="!isEmediaPlatform" />
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup,
  BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import { toastification } from '@core/mixins/toast'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import LeftImage from '@/layouts/components/LeftImage.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import envMixin from '@/mixins/envMixin'

const { mapActions } = createNamespacedHelpers('auth')
export default {
  components: {
    BrandLogo,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    LeftImage,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // components
    BtnLoading,

    // validate
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification, envMixin],
  data() {
    return {
      cPassword: '',
      password: '',
      imgUrl: require('@/assets/images/pages/auth/reset-password.png'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    ...mapGetters({
      status: 'auth/status',
      loading: 'auth/loading',
      message: 'auth/message',
    }),
    hash() {
      return this.$route.query.hash
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    password1check() {
      return this.password.length >= 6 ? 'CheckIcon' : 'XIcon'
    },
    password2check() {
      return this.cPassword && this.password === this.cPassword ? 'CheckIcon' : 'XIcon'
    },
  },
  methods: {
    ...mapActions(['logout']),
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async validationForm() {
      const success = await this.$refs.simpleRules.validate()
      if (success) {
        const params = {
          hash: this.hash,
          newPassword: this.password,
        }
        await this.$store.dispatch('auth/resetFinish', { params })

        if (this.status) {
          this.toastSuccess(this.$t('resetPW.textResetPWSuccess'))
          await this.logout()
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style lang="scss" scoped>
  .form-reset-password {
    padding: 0 2.2rem;
  }
  .label-note {
    margin-top: .75rem;
    margin-bottom: 1.5rem;
  }
  .btn-confirm-password {
    margin-bottom: 2.5rem;
  }
  .input-height {
    height: 50px;
  }
  .txt-color{
    color: #FCF7F8
  }

  .input-group-append:not(:last-child) .input-group-text{
    border: none;
    padding: 0;
  }

  .feather-check{
    color: #1DB9C3;
    width: 20px;
    height: 20px;
  }

  .feather-x{
    color: #E11C4E;
    width: 20px;
    height: 20px;
  }

  /* .text-or{
    color: rgba(22, 33, 62, 0.5);
  }

  .text-or:before, .text-or:after {
    content: "";
    display: inline-block;
    width: 44%;
    margin: 0 24px 0 -55%;
    vertical-align: middle;
    border-bottom: 1px solid rgba(22, 33, 62, 0.1);
  }
  .text-or:after {
    margin: 0 -55% 0 24px;
  } */
  @media (max-width: 767px) {
    .form-reset-password {
      padding-left: 0;
      padding-right: 0;
      .description {
        line-height: 24px;
        margin-bottom: 2rem !important;
      }
    }
    .navbar-auth-custom {
      width: calc(100%);
    }
    .card-title {
      padding-top: 2.5rem;
    }
    .card-text:last-child {
      margin-bottom: 2.2rem;
    }
  }

  .bg-reset-password {
    background-color: #f8f8fa;
  }
</style>
